@use 'mixins';

$z-background: 0;
$z-thumbnail: 1;
$z-gradient: 2;
$z-text: 3;
$z-overlay: 4;

.ViBlock {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: #{$z-background};
    border-radius: var(--standardRadius);
    overflow: hidden;

    &.pro {
        background: rgb(var(--invertedBackground));
        color: rgb(var(--invertedTextColor));

        h4 {
            font-family: var(--pro-article-heading-font-family);
        }

        .title {
            padding: 0 var(--standardPadding) var(--standardPadding) var(--standardPadding);
        }
    }

    .image-container {
        display: flex;
        flex: 1;
        position: relative;
        width: 100%;
        height: 100%;
    }

    .image {
        width: 100%;
        height: 100%;
        z-index: #{$z-thumbnail};
    }

    .overlay {
        display: block;
        position: absolute;
        z-index: #{$z-overlay};
        inset: 0;

        > [class^='ProTag'] {
            position: absolute;
            top: var(--standardPadding);
            right: var(--standardPadding);
        }

        > [class^='Time'] {
            position: absolute;
            bottom: var(--standardPadding);
            left: var(--standardPadding);
        }
    }

    .overlay-gradient {
        @include mixins.background-overlay-fill(transparent, rgb(var(--invertedBackground)));

        z-index: #{$z-gradient};
    }

    .title {
        display: block;
        position: relative;
        z-index: #{$z-text};
        aspect-ratio: 3 / 1;

        h4 {
            display: inline;
            height: min-content;

            @include mixins.row-limit(3);
        }
    }
}

@include mixins.responsive(l, above) {
    .ViBlock {
        .title {
            aspect-ratio: auto;

            h4 {
                @include mixins.row-limit(2);
            }
        }
    }
}
